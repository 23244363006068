
























import PieChartTemplate from "@/components/Common/Chart/PieChartTemplate";
import Card from "@/components/Common/Card";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { computed } from "@vue/composition-api";
import { moment } from "@core/filters";
import store from "@/store";
interface Props {
  title: string | null;
  isLoading: boolean;
  type: string;
  data: any[];
}
const __sfc_main = {};
__sfc_main.props = {
  title: {
    key: "title",
    required: false,
    type: [String, null],
    default: null
  },
  isLoading: {
    key: "isLoading",
    required: false,
    type: Boolean,
    default: false
  },
  type: {
    key: "type",
    required: false,
    type: String,
    default: "pie"
  },
  data: {
    key: "data",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const firstPeriod = computed(() => store.state.analyticTargetAudience.filters.firstPeriod);
  const hasPie = computed(() => props.type === "pie");
  const labelPeriod = computed(() => {
    const {
      dateStart,
      dateEnd
    } = firstPeriod.value;
    return `${formatDate(dateStart)} - ${formatDate(dateEnd)}`;
  });
  const items = computed(() => {
    return hasPie.value ? formatData(props.data).sort((a: any, b: any) => b.value - a.value) : props.data?.map(({
      data,
      legendName
    }) => formatData(data, legendName));
  });
  function tooltipFormatter({
    name
  }: {
    name: string;
  }): string {
    const [key, value] = name?.split(" - ");
    return `${key}   ${value}`;
  }
  function legendFormatter(name: string): string {
    const [key, value] = name?.split(" - ");
    return `{value|${key}}      ${value}`;
  }
  function formatData(data: any[], legendName?: string) {
    return data?.map(({
      key,
      value
    }) => {
      const name = `${key} - ${value}`;
      const _value = typeof value === "string" ? Number(value.replace("%", "")) : value;
      return {
        name,
        value: _value,
        legendName
      };
    }) ?? [];
  }
  function formatDate(date: Date): string {
    return moment(date, "DD.MM.YYYY");
  }
  return {
    hasPie,
    labelPeriod,
    items,
    tooltipFormatter,
    legendFormatter
  };
};
__sfc_main.components = Object.assign({
  Card,
  PieChartTemplate
}, __sfc_main.components);
export default __sfc_main;
