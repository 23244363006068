import { OfferService as commonOfferService } from "@core/services/common/offer/OfferService";
import { OfferLandingsState } from "@core/store/types/common/offerLandings";
import { OfferService } from "@core/services/admin/offer/OfferService";
import { hasPermissionsStrict } from "@core/mixins/permissions";
import { Offer } from "@core/logic/admin/offer/offer";
import { defineStore } from "pinia";
import store from "@/store";

export interface OfferState {
  offer: Nullable<Partial<Offer>>;
}

export const useOfferDetail = defineStore("admin/offer", {
  state: (): OfferState => ({
    offer: null
  }),

  getters: {
    userPermissions: (): boolean => store.state.auth.userPermissions,
    isEditOffer (): boolean { return hasPermissionsStrict(["OFFERS.EDIT"], this.userPermissions); },
    isCreatedOffer (): boolean { return hasPermissionsStrict(["OFFERS.CREATE", "STATISTICS.SHOW_COMMISSION"], this.userPermissions); },
    firstPeriod: (): OfferLandingsState["landings"] => store.state.analyticTargetAudience.filters.firstPeriod,
    dataRedemptionGender: () => store.getters["analyticTargetAudience/REDEMPTION_SHARE_BY_GENDER"],
    dataRedemptionAge: () => store.getters["analyticTargetAudience/REDEMPTION_SHARE_BY_AGE"],
    dataAgePercent: () => store.getters["analyticTargetAudience/SHARE_ORDERS_BY_AGE"],
    dataGender: () => store.getters["analyticTargetAudience/SHARE_ORDERS_BY_GENDER"],
    offerId: ({ offer }) => offer?.id
  },

  actions: {
    async GET_OFFER (offerId: string): Promise<void> {
      if (offerId) {
        this.offer = await OfferService.getOffer(offerId) as unknown as Offer;
      }
    },

    async GET_OFFER_INT_ID (slug: string): Promise<void> {
      const intId = +slug.split("-")[0];
      if (intId) {
        this.offer = await commonOfferService.getOfferByIntId(intId, store.getters.role) as unknown as Offer;
      }
    }
  }
});
