
















































































































import WebmastersTableDetailsModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal.vue";
import TablePlaceholder from "@/components/Common/Table/TablePlaceholder.vue";
import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
import AdminOutput from "@/components/Common/Output/AdminOutput.vue";
import CompactList from "@/components/Common/Lists/CompactList.vue";
import UserOutput from "@/components/Common/Output/UserOutput.vue";
import LTable from "@/components/Common/LTable.vue";
import { debounceUpdateByWatchedParams } from "@/helpers/debounceUpdateByWatchedParams";
import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { useWebmastersByOffer } from "@/stores/admin/offer/webmastersByOfferStore";
import { formatCurrency, formatEmptyString } from "@core/filters";
import { OfferWebmaster } from "@core/store/types/admin/offer/webmasters";
import { GET_OFFER_WEBMASTERS } from "@core/store/action-constants";
import { useOffers } from "@/stores/admin/offers/offersStore";
import { defineProps, withDefaults } from "@vue/runtime-dom";
import { onUnmounted, Ref, ref } from "@vue/composition-api";
import { Offer } from "@core/logic/admin/offer/offer";
import { useWait } from "@/stores/common/waitStore";
import { TranslateResult } from "vue-i18n";
import i18n from "@core/plugins/i18n";
import { storeToRefs } from "pinia";
import vuexStore from "@/store";
import { SubTypeEnum } from "@core/store/types/advertiser/profile/enums/SubTypeEnum";
interface Props {
  offer: {
    finance: Offer;
    id: string;
  } | null;
}
const __sfc_main = {};
__sfc_main.props = {
  offer: {
    key: "offer",
    required: false,
    type: [Object, null],
    default: null
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = useWebmastersByOffer();
  const {
    data,
    pagination,
    filters,
    offerId
  } = storeToRefs(store);
  const {
    isShowCommissions
  } = useOffers();
  const isLoading = useWait(store, GET_OFFER_WEBMASTERS);
  offerId.value = props.offer?.id;
  const detailsModal = ref(false);
  const detailsModalData = ref(null) as unknown as Ref<OfferWebmaster["webmaster"]>;
  debounceUpdateByWatchedParams({
    pagination: pagination.value,
    filters: filters?.value
  }, store.GET_OFFER_WEBMASTERS, true);
  function manager(row: OfferWebmaster): OfferWebmaster["webmaster"]["admins"] {
    return row.webmaster?.admins;
  }
  function calcCommission({
    actualPayout,
    individualRate
  }: OfferWebmaster, hasReward = false): string | undefined {
    if (actualPayout) {
      const reward = hasReward ? individualRate?.rate?.value ?? props.offer?.finance?.rate?.value : 0;
      return formatCurrency(actualPayout.value - reward, actualPayout.currency);
    }
    return formatEmptyString(null);
  }
  function calcAgentCommission({
    agentCommission,
    webmaster: {
      subType
    },
    actualPayout
  }: OfferWebmaster): string | undefined {
    if (subType === SubTypeEnum.SUB_WEBMASTER) {
      return formatCurrency(agentCommission?.value ?? 0, agentCommission?.currency ?? actualPayout.currency);
    }
    return "";
  }
  function withdrawType(row: OfferWebmaster): TranslateResult {
    const withdrawType = (row.individualRate?.withdrawType || props.offer?.finance?.withdrawType)?.toLowerCase();
    return withdrawType ? i18n.t(`common.entity.withdrawType.${withdrawType}`) : formatEmptyString(null);
  }
  function moneyReward(row: OfferWebmaster): string | undefined {
    const reward = row.individualRate?.rate || props.offer?.finance?.rate;
    return formatCurrency(reward?.value, reward?.currency);
  }
  function webmastersTableDetailsModalOpen(value: OfferWebmaster["webmaster"]) {
    detailsModal.value = true;
    detailsModalData.value = value;
    vuexStore.dispatch("admin/users/webmasters/detail/UPDATE_WEBMASTER_ID", value.id);
  }
  onUnmounted(() => {
    store.$reset();
  });
  return {
    VerticalsEnum,
    data,
    pagination,
    isShowCommissions,
    isLoading,
    detailsModal,
    detailsModalData,
    manager,
    calcCommission,
    calcAgentCommission,
    withdrawType,
    moneyReward,
    webmastersTableDetailsModalOpen
  };
};
__sfc_main.components = Object.assign({
  TablePlaceholder,
  LTable,
  WebmasterOutput,
  CompactList,
  AdminOutput,
  UserOutput,
  WebmastersTableDetailsModal
}, __sfc_main.components);
export default __sfc_main;
